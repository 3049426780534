import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchProducts } from "../../features/productsSlice";
import axios from "axios";

function CreateProduct() {
  const [product, setProduct] = useState({
    name: "",
    desc: "",
    price: "",
    image: null,
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const adminLoggedIn = useSelector((state) => state.adminLogin.loggedIn);
  const adminToken = useSelector((state) => state.adminLogin.token);

  useEffect(() => {
    if (!adminLoggedIn || !adminToken) {
      navigate("/admin-login");
    } else {
      setAuthenticated(true);
    }
  }, [adminLoggedIn, adminToken, navigate]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    const reader = new FileReader();

    if (file) {
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setProduct((prevProduct) => ({
          ...prevProduct,
          image: reader.result,
        }));
      };
    } else {
      setProduct((prevProduct) => ({
        ...prevProduct,
        image: null,
      }));
      console.log(product.image);
      console.error("error with upload");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("name", product.name);
      formData.append("desc", product.desc);
      formData.append("price", product.price);
      formData.append("image", product.image);

      const response = await axios.post(
        "https://marshall-wears-backend-z2mi.onrender.com/api/6bNcXx4t2zFhLmPc/add-product",
        formData
      );

      setSaved(response.data.message);
      console.log(response.data.message);
      setLoading(false);

      setProduct({
        name: "",
        desc: "",
        price: "",
        image: null,
      });

      dispatch(fetchProducts());
    } catch (err) {
      console.error(err.response.data.message);
      setError(err.response.data.message);
    }
  };

  return (
    authenticated && (
      <div className="add-product-section">
        {error && <div>{error}</div>}
        {saved && <div>{saved}!</div>}
        {loading ? (
          <div>Loading...</div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div>Product Name:</div>
            <input
              type="text"
              name="name"
              value={product.name}
              onChange={handleChange}
            />
            <div>Description:</div>
            <textarea
              name="desc"
              value={product.desc}
              onChange={handleChange}
            ></textarea>
            <div>Price:</div>
            <input
              type="number"
              name="price"
              value={product.price}
              onChange={handleChange}
            />
            <div>Image:</div>
            <input type="file" accept="image/" onChange={handleImageUpload} />
            <button type="submit">Post</button>
          </form>
        )}

        <div>
          <img src={product.image} alt="Product preview" />
        </div>
      </div>
    )
  );
}

export default CreateProduct;
