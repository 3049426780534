import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import NavBar from "./components/NavBar";
import Home from "./components/Home";
import Cart from "./components/Cart";
import ViewDress from "./components/ViewDress";
import CartPayment from "./components/CartPayment";
import SearchResult from "./components/SearchResult";
import RegisterPage from "./components/authPages/RegisterPage";
import LoginPage from "./components/authPages/LoginPage";
import Admin from "./components/admin/Admin";
import AdminLogin from "./components/admin/AdminLogin";
import Products from "./components/admin/Products";
import CreateProduct from "./components/admin/CreateProduct";
import Users from "./components/admin/Users";
import Payment from "./components/admin/Payment";
import NotFoundPage from "./components/NotFoundPage";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ToastContainer />
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/view-dress" element={<ViewDress />} />
          <Route path="/cart-payment" element={<CartPayment />} />
          <Route path="/search-results" element={<SearchResult />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/admin-login" element={<AdminLogin />} />
          <Route path="/admin" element={<Admin />}>
            <Route path="products" element={<Products />}>
              <Route path="create-product" element={<CreateProduct />} />
            </Route>
            <Route path="users" element={<Users />} />
            <Route path="payments" element={<Payment />} />
          </Route>
          <Route path="*" element={<Navigate to="/not-found" />} />
          <Route path="/not-found" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
