import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addToCart } from "../features/cartSlice";
import ReactImageMagnify from "react-image-magnify";

const ViewDress = () => {
  const viewDressArray = useSelector((state) => state.viewDress.viewDressArray);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    if (viewDressArray.length === 0) {
      navigate("/");
    }
  }, [viewDressArray, navigate]);

  return (
    viewDressArray[0] && (
      <>
        <div
          className="super-view-dress"
          onMouseEnter={() => window.innerWidth < 700 && setIsHovering(false)}
          onClick={() => window.innerWidth > 700 && setIsHovering(false)}
        >
          <div
            className="view-dress-div"
            onMouseEnter={() => window.innerWidth < 700 && setIsHovering(false)}
            onMouseLeave={() => window.innerWidth > 700 && setIsHovering(false)}
          >
            {isHovering ? (
              <ReactImageMagnify
                {...{
                  smallImage: {
                    alt: viewDressArray[0].name,
                    src: viewDressArray[0].image,
                    isFluidWidth: true,
                    width: 200,
                    height: 800,
                  },
                  largeImage: {
                    src: viewDressArray[0].image,
                    width: 1200,
                    height: 1800,
                  },
                }}
              />
            ) : (
              <img
                src={viewDressArray[0].image}
                alt={viewDressArray[0].name}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
              />
            )}
          </div>

          <div className="right-view-dress">
            <div
              style={{
                paddingLeft: "24px",
                paddingRight: "24px",
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  marginBottom: "5px",
                  display: "flex",
                  flexWrap: "wrap",
                  fontSize: "25px",
                }}
              >
                {viewDressArray[0].name}
              </div>
              <div
                style={{
                  marginBottom: "5px",
                  display: "flex",
                  flexWrap: "wrap",
                  fontSize: "25px",
                }}
              >
                {viewDressArray[0].desc}
              </div>
              <div
                style={{
                  marginBottom: "5px",
                  display: "flex",
                  flexWrap: "wrap",
                  fontWeight: "bold",
                  fontSize: "40px",
                }}
              >
                £{viewDressArray[0].price.toLocaleString()}
              </div>
            </div>

            <div className="add-div">
              <div
                className="view-dress-add"
                onClick={() => {
                  dispatch(addToCart(viewDressArray[0]));
                  navigate("/cart");
                }}
              >
                Add To Cart
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default ViewDress;
