import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import jwtDecode from "jwt-decode";

export const registerUser = createAsyncThunk(
  "auth/registerUser",
  async (userData, thunkAPI) => {
    try {
      const response = await axios.post(
        "https://marshall-wears-backend-z2mi.onrender.com/api/2htsiRg1o7JWVjHx/register",
        userData
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response.data || "An error occurred"
      );
    }
  }
);

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (userCheck, thunkAPI) => {
    try {
      const response = await axios.post(
        "https://marshall-wears-backend.onrender.com/api/8dMfYk5v9RnQpSgL/login",
        userCheck
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response.data || "An error occurred"
      );
    }
  }
);

const initialState = {
  token: localStorage.getItem("userToken") || "",
  email: "",
  phoneNumber: "",
  username: "",
  registerLoading: false,
  registerSuccess: false,
  registerError: null,
  loginLoading: false,
  loginSuccess: false,
  loginError: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logoutUser: (state) => {
      state.token = "";
      state.email = "";
      state.phoneNumber = "";
      state.username = "";
      state.registerLoading = false;
      state.registerSuccess = false;
      state.registerError = null;
      state.loginLoading = false;
      state.loginSuccess = false;
      state.loginError = null;
      localStorage.removeItem("userToken");
    },
    extractUserFromToken: (state) => {
      if (state.token) {
        const decodedUser = jwtDecode(state.token);
        state.email = decodedUser.email;
        state.phoneNumber = decodedUser.phoneNumber;
        state.username = decodedUser.username;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.registerSuccess = false;
        state.registerError = null;
        state.registerLoading = true;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.registerLoading = false;
        state.registerError = null;
        state.registerSuccess = true;
        state.token = action.payload;
        localStorage.setItem("userToken", action.payload);
        const decodedUser = jwtDecode(state.token);
        state.email = decodedUser.email;
        state.phoneNumber = decodedUser.phoneNumber;
        state.username = decodedUser.username;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.registerLoading = false;
        state.registerSuccess = false;
        state.registerError = action.payload;
      })
      .addCase(loginUser.pending, (state) => {
        state.loginSuccess = false;
        state.loginError = null;
        state.loginLoading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loginLoading = false;
        state.loginError = null;
        state.loginSuccess = true;
        state.token = action.payload;
        localStorage.setItem("userToken", action.payload);
        const decodedUser = jwtDecode(state.token);
        state.email = decodedUser.email;
        state.phoneNumber = decodedUser.phoneNumber;
        state.username = decodedUser.username;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loginLoading = false;
        state.loginSuccess = false;
        state.loginError = action.payload;
      });
  },
});

export const { logoutUser, extractUserFromToken } = authSlice.actions;
export default authSlice.reducer;
