import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addToCart } from "../features/cartSlice";
import { pushDress } from "../features/viewDressSlice";
import { fetchProducts } from "../features/productsSlice";
import { useEffect } from "react";
//import whatsappLogo from "../Images/whatsapplogo.png";

const Home = () => {
  const products = useSelector((state) => state.products.products);
  const status = useSelector((state) => state.products.status);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // Dispatch the fetchProducts action when the component mounts
    dispatch(fetchProducts());
  }, [dispatch]);

  return (
    <div className="home">
      <>
        {status === "loading" && (
          <div className="loading-overlay">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="home-heading">NEW ARRIVALS</div>
        {/*status === "succeeded" && (
          <Link to="https://wa.me/2349072637906" className="whatsapp-link">
            <img
              src={whatsappLogo}
              className="whatsapp-logo"
              alt="Whatsapp Link"
            />
          </Link>
        )*/}
        <div className="products">
          {products.map((eachProduct) => (
            <div key={eachProduct._id} className="product">
              <img
                onClick={() => {
                  dispatch(pushDress(eachProduct));
                  navigate("/view-dress");
                }}
                src={eachProduct.image}
                alt={eachProduct.name}
              />
              <div className="product-name">{eachProduct.name}</div>
              <div className="details">
                <span>{eachProduct.desc}</span>
                <span className="price">
                  £{eachProduct.price.toLocaleString()}
                </span>
              </div>
              <button
                onClick={() => {
                  dispatch(addToCart(eachProduct));
                  navigate("/cart");
                }}
              >
                Add To Cart
              </button>
            </div>
          ))}
        </div>
      </>
    </div>
  );
};

export default Home;
