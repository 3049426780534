import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function Users() {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const adminLoggedIn = useSelector((state) => state.adminLogin.loggedIn);
  const adminToken = useSelector((state) => state.adminLogin.token);

  const navigate = useNavigate();

  useEffect(() => {
    if (!adminLoggedIn || !adminToken) {
      navigate("/admin-login");
    } else {
      setAuthenticated(true);
      async function fetchUsers() {
        try {
          const response = await fetch(
            "https://marshall-wears-backend-z2mi.onrender.com/api/D8cRmK5f2vNpTqZu/all-users"
          );
          const data = await response.json();
          setUsers(data);
          setLoading(false);
        } catch (err) {
          console.error(err.message);
          setError(err.message);
          setLoading(false);
        }
      }

      fetchUsers();
    }
  }, [adminLoggedIn, adminToken, navigate]);

  if (loading) {
    return (
      <div className="loading-overlay">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  return (
    authenticated && (
      <div className="users-container">
        <div className="customer-info">All Customer Info</div>
        {error && <div className="error">{error}</div>}
        {users.length === 0 ? (
          <div className="no-users">No registered users found.</div>
        ) : (
          <ul className="user-list">
            {users.map((user) => (
              <div key={user._id} className="user-row">
                <div className="user-info">username: {user.username}</div>
                <div className="user-info">email: {user.email}</div>
                <div className="user-info">phone: {user.phoneNumber}</div>
              </div>
            ))}
          </ul>
        )}
      </div>
    )
  );
}

export default Users;
