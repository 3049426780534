// adminLoginSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const adminLogin = createAsyncThunk(
  "adminLogin/login",
  async (adminCheck, thunkAPI) => {
    try {
      const response = await axios.post(
        "https://marshall-wears-backend-z2mi.onrender.com/api/7N4xh9eD3X2gP5Fv/admin-login",
        adminCheck
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

const adminLoginSlice = createSlice({
  name: "adminLogin",
  initialState: {
    loading: false,
    error: null,
    loggedIn: false,
    token: "",
  },
  reducers: {
    logout: (state) => {
      state.loading = false;
      state.error = null;
      state.loggedIn = false;
      state.token = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(adminLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.loggedIn = false;
        state.token = "";
      })
      .addCase(adminLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.loggedIn = true;
        state.token = action.payload;
      })
      .addCase(adminLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.loggedIn = false;
        state.token = "";
      });
  },
});

export const { logout } = adminLoginSlice.actions;

export default adminLoginSlice.reducer;
