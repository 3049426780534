import { useSelector, useDispatch } from "react-redux";
import { addToCart } from "../features/cartSlice";
import { useNavigate } from "react-router-dom";
//import whatsappLogo from "../Images/whatsapplogo.png";

const SearchResults = () => {
  const searchItems = useSelector((state) => state.search.searchItems);
  const status = useSelector((state) => state.search.status);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className="home">
      {status === "loading" ? (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      ) : (
        <>
          {/*
          <Link to="https://wa.me/2349072637906" className="whatsapp-link">
            <img
              src={whatsappLogo}
              className="whatsapp-logo"
              alt="Whatsapp Link"
            />
          </Link>
          */}
          <div className="home-heading">NEW ARRIVALS</div>
          <div className="products">
            {searchItems.map((product) => (
              <div key={product._id} className="product">
                <img src={product.image} alt={product.name} />
                <div className="product-name">{product.name}</div>
                <div className="details">
                  <span>{product.desc}</span>
                  <span className="price">£{product.price}</span>
                </div>
                <button
                  onClick={() => {
                    dispatch(addToCart(product));
                    navigate("/cart");
                  }}
                >
                  Add To Cart
                </button>
              </div>
            ))}
          </div>
        </>
      )}
      {searchItems.length === 0 && <div>No results found.</div>}
    </div>
  );
};

export default SearchResults;
