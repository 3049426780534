import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../features/authSlice";
import { searchProduct } from "../features/searchProductSlice";
import { toast } from "react-toastify";
import { BiSearch } from "react-icons/bi";
import { BsFillArrowRightCircleFill } from "react-icons/bs";

const NavBar = () => {
  const cartTotalQuantity = useSelector(
    (state) => state.cart.cartTotalQuantity
  );
  const token = useSelector((state) => state.auth.token);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchData, setSearchData] = useState("");
  const [displaySearch, setDisplaySearch] = useState(false);

  const toggleSearch = () => {
    if (displaySearch) {
      setDisplaySearch(false);
    } else {
      setDisplaySearch(true);
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();

    if (searchData.trim() !== "") {
      try {
        await dispatch(searchProduct(searchData.trim()));
        navigate("/search-results");
        setDisplaySearch(false);
        setSearchData("");
      } catch (error) {
        toast.error("Error searching for products!", {
          position: "bottom-left",
        });
      }
    }
  };

  return (
    <header className="nav-bar">
      <Link to="/" id="logo-link">
        <img
          src="https://res.cloudinary.com/dynmdbdfu/image/upload/v1715494843/Marshall_s_2_rp5lil.png"
          className="logo"
          alt="Feminine Styles Logo"
        />
      </Link>
      {window.innerWidth >= 700 && (
        <form onSubmit={handleSearch} className="search-area">
          <div className="search-area-div">
            <input
              id="search-input"
              type="text"
              placeholder="Search for products"
              value={searchData}
              onChange={(e) => setSearchData(e.target.value)}
            />
            <button type="submit" id="search-but">
              <BsFillArrowRightCircleFill className="search-area-btn" />
            </button>
          </div>
        </form>
      )}
      <Link to="/cart" className="link-cart-bag">
        <div className="cart-bag">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="35"
            fill="currentColor"
            className="bi bi-cart-fill"
            viewBox="0 0 16 16"
          >
            <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
          </svg>
          <div className="bag-quantity">
            <div>{cartTotalQuantity}</div>
          </div>
        </div>
      </Link>
      {window.innerWidth < 700 && (
        <div className="search-div">
          <BiSearch className="won" onClick={toggleSearch} />
        </div>
      )}
      {window.innerWidth > 700 && (
        <div className="first-timers">First timers - 50% off</div>
      )}
      <img
        id="uk-flag"
        src="https://upload.wikimedia.org/wikipedia/en/a/ae/Flag_of_the_United_Kingdom.svg"
        alt="UK"
      />
      {token ? (
        <div
          onClick={() => {
            dispatch(logoutUser());
            toast.warning("Logged out!", { position: "bottom-left" });
          }}
          className="loginlogout"
        >
          Welcome!
          <Link to="/login" className="logout">
            Logout
          </Link>
        </div>
      ) : (
        <div className="loginlogout">
          <Link to="/login" className="login">
            Login
          </Link>
          <Link to="/register" className="register">
            Register
          </Link>
        </div>
      )}
      {window.innerWidth < 700 && displaySearch && (
        <form onSubmit={handleSearch} className="search-area">
          <div className="search-area-div">
            <input
              id="search-input"
              type="text"
              placeholder="Search for products"
              value={searchData}
              onChange={(e) => setSearchData(e.target.value)}
            />
            <button type="submit" id="search-but">
              <BsFillArrowRightCircleFill className="search-area-btn" />
            </button>
          </div>
        </form>
      )}
    </header>
  );
};

export default NavBar;
